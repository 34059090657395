import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import pages from './utils/Pages';
import Navbar from './components/Navbar';
import PreNavbar from './components/PreNavbar';
import Footer from './components/Footer';
import SubFooter from './components/SubFooter';
import Page404 from './pages/Page404';

function App() {
  return (
    <BrowserRouter>
      <PreNavbar />
      <Navbar />
      <Routes>
        { pages.map(page => <Route path={page.path} element={<page.render/>} />) }
        <Route path='*' element={<Page404.render/>} />
      </Routes>
      <Footer />
      <SubFooter />
    </BrowserRouter>
  );
}

export default App;
