function PreNavbar(): JSX.Element {
  return (
    <div className="base-page py-1 my-0 bg-white">
      <div className="p-4 pb-1">
        <div className="d-flex flex-column align-items-baseline">
          <img src={require("../img/Logo-OsteoNatural-Pescara.png")} className="pe-2" />
          <h2 style={{color: "#8aac1a"}}>del <b>Dott. Luca Bongrazio</b></h2>
        </div>

        <p>
          Osteopatia biodinamica, biomeccanica e craniosacrale, ginnastica medica, fisioterapia, naturopatia, fitoterapia
        </p>
      </div>
    </div>
  );
}

export default PreNavbar;