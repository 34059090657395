import { Page } from "../utils/Pages";

function Render(): JSX.Element {
  return (
    <div className="base-page">
      <h1>Trattamenti</h1>

      <h2 style={{color: "#8aac1a"}}><b>OSTEOPATIA E RIEDUCAZIONE MOTORIA</b></h2>

      <p>Lo <b>Studio OsteoNatural</b> effettua un'ampia varietà di trattamenti e terapie manuali osteopatiche.</p>

      <ul>
        <li>Terapia cranio-sacrale bambini</li>
        <li>Fitoterapia</li>
        <li>Floriterapia</li>
        <li>Massaggio sportivo</li>
        <li>Bendaggio e taping</li>
      </ul>

      <p>Queste tecniche sono utili per risolvere una moltitudine di problematiche, dolori e malesseri del nostro corpo.</p>

      <p>
        <b>Problemi lombosacrali:</b> mal di schiena, dolori lombari, dorsalgia, sciatalgia, ernia del disco. <br />
        <b>Sfera cranica:</b> mal di testa, cefalea, emicrania, stress, fatica cronica, vertigini, malocclusioni. <br />
        <b>Collo e spalle:</b> cervicalgie e problemi alla cervicale, torcicollo, distorsioni, colpo di frusta. <br />
        <b>Dolori agli arti superiori e inferiori:</b> dolori all'anca, al ginocchio, alla spalla, al gomito, al polso e alle mani. <br />
        <b>Ansia e stress:</b> fatica cronica, instabilità, fibromialgia, stati di agitazione, disturbi del sonno, bruxismo, altri disturbi derivanti dallo stress. <br />
        <b>Problemi dei neonati:</b> coliche, rigurgiti, otiti, conseguenze trauma da parto.
      </p>

      <hr />

      <p><b>Esistono diverse tipologie di tecniche che l'osteopata ha a propria disposizione per curare le disfunzioni del corpo umano:</b></p>

      <p>
        <b>Trattamento osteopatico generale</b> <br />
        Applicazioni di particolari manovre di rilasciamento delle articolazioni e dei muscoli.
        Ha effetti non solo meccanici, ma soprattutto biochimici, in quanto stimola il corretto scambio di fluidi all'interno delle strutture trattate. <br />
        <i>Manipolazioni articolari:</i> tecniche dirette che correggono le posizioni spaziali delle articolazioni secondo i loro assi di movimento.
        Hanno una forte influenza neurologica, oltre che puramente meccanica, in quanto favoriscono l'emissione di corretti impulsi dalle e alle terminazioni nervose della parte trattata.
      </p>

      <p>
        <b>Manipolazioni viscerali</b> <br />
        Ristabiliscono la mobilità e la motilità (espressione della vitalità cellulare) di un organo.
        Queste tecniche consentono di stimolare l'organo verso una corretta funzione, digestiva, di assorbimento o di espulsione, sia in un ambito più meccanico sia in ambito biochimico.
      </p>

      <p>
        <b>Tecniche craniali</b> <br />
        Agiscono sulo movimento di congruenza fra le ossa del cranio, andando ad agire a livello osseo, nervoso, meningeo e del liquido cefalo-rachidiano.
        Con queste tecniche si agisce in particolare sulla vitalità dell'organismo, qualità fondamentale che permette agli esseri viventi di reagire con efficacia agli eventi di disturbo provenienti dall'ambiente esterno e da quello interno.
      </p>

      <hr className="my-5" />

      <div className="grid-container-5">
        <img src={require("../img/dim1.jpeg")} style={{width: "100%"}} />
        <img src={require("../img/dim2.jpeg")} style={{width: "100%"}} />
        <img src={require("../img/dim3.jpeg")} style={{width: "100%"}} />
        <img src={require("../img/dim4.jpeg")} style={{width: "100%"}} />
        <img src={require("../img/dim5.jpeg")} style={{width: "100%"}} />
        <img src={require("../img/dim6.jpeg")} style={{width: "100%"}} />
        <img src={require("../img/dim7.jpeg")} style={{width: "100%"}} />
        <img src={require("../img/dim8.jpeg")} style={{width: "100%"}} />
        <img src={require("../img/dim9.jpeg")} style={{width: "100%"}} />
      </div>

      <div className="grid-container-5 mt-2">
        <video width="100%" height="478px" className="mt-2" autoPlay controls loop style={{backgroundColor: "black"}}>
          <source src={require("../img/dim11.mp4")} type="video/mp4" />
        </video>

        <video width="100%" height="478px" className="mt-2" controls loop style={{backgroundColor: "black"}}>
          <source src={require("../img/dim10.mp4")} type="video/mp4" />
        </video>

        <video width="100%" height="478px" className="mt-2" controls loop style={{backgroundColor: "black"}}>
          <source src={require("../img/dim12.mp4")} type="video/mp4" />
        </video>

        <video width="100%" height="478px" className="mt-2" controls loop style={{backgroundColor: "black"}}>
          <source src={require("../img/dim13.mp4")} type="video/mp4" />
        </video>

        <video width="100%" height="478px" className="mt-2" controls loop style={{backgroundColor: "black"}}>
          <source src={require("../img/dim14.mp4")} type="video/mp4" />
        </video>

        <video width="100%" height="478px" className="mt-2" controls loop style={{backgroundColor: "black"}}>
          <source src={require("../img/dim15.mp4")} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}

const Trattamenti: Page = {
  name: "TRATTAMENTI",
  description: "I miei trattamenti.",
  path: "/trattamenti",
  render: Render,
  showInNavbar: true,
}

export default Trattamenti;