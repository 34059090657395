import { Link } from "react-router-dom";
import { Page } from "../utils/Pages";

function Render(): JSX.Element {
  return (
    <div className="base-page">
      <div className="d-flex flex-column align-items-center">
        <h1>404</h1>
        <h2>Oops! Pagina non trovata!</h2>
        <Link to={'/'}>TORNA ALLA HOME</Link>
      </div>
    </div>
  );
}

const Page404: Page = {
  name: "Page404",
  description: "Pagina non trovata.",
  path: "/404",
  render: Render,
  showInNavbar: true,
}

export default Page404;