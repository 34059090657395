import { Page } from "../utils/Pages";

function Render(): JSX.Element {
  return (
    <div className="base-page">
      <h1>Contatti</h1>

      <p>
        Manda una e-mail a <a href="mailto:lucabongrazio21@gmail.com">lucabongrazio21@gmail.com</a> o chiamami al <a href="tel:3206287825">3206287825</a> per prendere un appuntamento o avere informazioni sui corsi.
      </p>
    </div>
  );
}

const Contatti: Page = {
  name: "CONTATTI",
  description: "I miei contatti.",
  path: "/contatti",
  render: Render,
  showInNavbar: true,
}

export default Contatti;