import { Page } from "../utils/Pages";

function Render(): JSX.Element {
  return (
    <div className="base-page">
      <h1>Luca Bongrazio</h1>
      <p>Esperto in: Colonna vertebrale, Mal di schiena, Lombalgia, Dolore cervicale, Posturologia.</p>

      <div className="card mb-3">
        <div className="row g-0">
          <div className="col-md-4">
            <img src={require("../img/Osteopata-Pescara-Luca-Bongrazio-223x300.jpg")} className="img-fluid rounded-start" alt="Luca Bongrazio" />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <p className="card-text">
                <b>Dottore in Osteopatia</b> <br/>
                <b>Dottore in Scienze Naturopatiche</b> <br/>
                <b>Dottore in Scienze Motorie e Sportive presso Università "G. D'Annunzio"</b> <br/>
                <b>Diplomato Massaggiatore sportivo</b>
              </p>

              <p>
                Nato a Pescara il 7 agosto 1969, una volta conseguito il Diploma Magistrale, inizio ad appassionarmi alla medicina naturale per un problema di salute personale.
                Nel 1999 conosco l'Osteopatia e da quel giorno comincio a studiare per qualificarmi professionalmente.
              </p>
            </div>
          </div>
        </div>
      </div>

      <p><b>FORMAZIONE</b></p>

      <ul>
        <li>Scuola di Osteopatia A.I.O.T. di Pescara (anno 2006)</li>
        <li>Osteopata D.O. SCUOLA ROI ABEOS</li>
        <li>Osteopata D.O. Scuola di Caserta</li>
        <li>Dottore in Scienze Naturopatiche</li>
        <li>Dottore in Scienze Motorie e Sportive</li>
        <li>Diploma di Massaggiatore sportivo</li>
        <li>Diploma di Dirigente di Comunità</li>
        <li>Diploma CONI Istruttore di Ginnastica Posturale Metodo Scientifico</li>
        <li>Corso di BLSD esecutore massaggio cardiaco presso ASL</li>
        <li>Corso di Strategie per il miglioramento della qualità presso ASL</li>
        <li>Corso di Kinesiologia applicata presso IMN di Pesaro Urbino</li>
        <li>Corso di Riflessologia Plantare 1,2,3 livello scuola zone riflesse</li>
        <li>Attestato delle competenze in Riflessologia Plantare Laboratori BORRI Roma</li>
        <li>Attestato di Terapia “La riabilitazione attraverso esercizi propriocettivi” presso Formazioneventi</li>
        <li>Corso di Back School 1-2-3 livello (Formazioneventi)</li>
        <li>Corso di Biodinamica e Biomeccanica</li>
        <li>Corso di Cranio-sacrale</li>
        <li>Corso di Operatore Specializzato nell'applicazione del Taping Elastico - ATS Advanced Training System</li>
        <li>Corso di Riabilitazione: “Il Taping nelle Applicazioni Neuromuscolari, Posturali e Linfatiche”</li>
      </ul>

      <p><b>COMPETENZE RELAZIONALI</b></p>

      <ul>
        <li>Abituato al lavoro di equipe a stabilire un rapporto autentico ed empatico con il paziente</li>
        <li>Approccio terapeutico olistico</li>
      </ul>
    </div>
  );
}

const ChiSono: Page = {
  name: "CHI SONO",
  description: "Chi fa parte del team (solo io).",
  path: "/chi-sono",
  render: Render,
  showInNavbar: true,
}

export default ChiSono;