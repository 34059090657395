import pages, { Page } from "../utils/Pages";

function Footer(): JSX.Element {
  const pagesToDisplay: Page[] = pages.filter(page => page.showInNavbar);

  return (
    <div className="osteonatural-footer p-4">
      <p className="mb-0">
        <i><b>Centro Studio Medico OsteoNatural del Dott. Luca Bongrazio</b></i> <br />
        <i>Via Colle Innamorati 9, Pescara</i> <br />

        <span>P.IVA 02339950681</span>
      </p>
    </div>
  );
}

export default Footer;