import { Link, useLocation } from "react-router-dom";
import pages, { Page } from "../utils/Pages";

function Navbar(): JSX.Element {
  const location = useLocation();

  const pagesToDisplay: Page[] = pages.filter(page => page.showInNavbar);

  return (
    // <div className="osteonatural-navbar grid-container-5 sticky-top">
    //   { pagesToDisplay.map(page =>
    //       location.pathname === page.path ?
    //       <Link to={page.path} style={{backgroundColor: "#47484a"}}>{page.name}</Link> :
    //       <Link to={page.path}>{page.name}</Link>
    //     )
    //   }
    // </div>

    <div className="osteonatural-navbar d-flex w-100 sticky-top">
    { pagesToDisplay.map(page =>
        location.pathname === page.path ?
        <Link to={page.path} className="flex-fill text-center" style={{backgroundColor: "#47484a"}}>{page.name}</Link> :
        <Link to={page.path} className="flex-fill text-center">{page.name}</Link>
      )
    }
  </div>
  );
}

export default Navbar;