import { Page } from "../utils/Pages";

function Render(): JSX.Element {
  return (
    <div className="base-page">
      <h1>Dove sono</h1>

      <ul>
        <li>Via Colle Innamorati 9, Pescara</li>
      </ul>

      {/*<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7001.316796217287!2d14.204005883361367!3d42.455588168906274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1331a6116fcb6d55%3A0xdc6aa522d8a89db0!2sVia%20Tiburtina%20Valeria%2C%2075%2C%2065128%20Pescara%20PE!5e0!3m2!1sit!2sit!4v1643923434420!5m2!1sit!2sit" width="600" height="450" allowFullScreen={false} loading="lazy"></iframe>*/}
      <iframe title="Pescara" width="100%" height="600" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=via%20colle%20innamorati%209+()&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
    </div>
  );
}

const DoveSono: Page = {
  name: "DOVE SONO",
  description: "Dove si trova lo studio.",
  path: "/dove-sono",
  render: Render,
  showInNavbar: true,
}

export default DoveSono;