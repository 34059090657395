import { Link } from "react-router-dom";
import pages, { Page } from "../utils/Pages";

function SubFooter(): JSX.Element {
  const pagesToDisplay: Page[] = pages.filter(page => page.showInNavbar);

  return (
    <div className="osteonatural-subfooter p-2">
      <div>
        <Link to='https://www.facebook.com/OsteoNatural'><i className="bi bi-facebook p-2"></i></Link>
        <Link to='mailto:lucabongrazio21@gmail.com'><i className="bi bi-envelope-fill p-2"></i></Link>
        <Link to='tel:3206287825'><i className="bi bi-telephone-fill p-2"></i></Link>
      </div>
    </div>
  );
}

export default SubFooter;