import ChiSono from "../pages/ChiSono";
import Contatti from "../pages/Contatti";
import DoveSono from "../pages/DoveSono";
import Home from "../pages/Home";
import Trattamenti from "../pages/Trattamenti";

export interface Page {
  name: string;
  description: string;
  path: string;
  render: () => JSX.Element;
  showInNavbar: boolean;
}

const pages: Page[] = [
  Home,
  ChiSono,
  Trattamenti,
  DoveSono,
  Contatti,
];

export default pages;